import { createEffect } from 'solid-js';
import { cn } from '~/utils';

export interface AdsProps {
  class?: string;
  section:
    | 'home_728x90_1'
    | 'home_728x90_2'
    | 'free_this_week_728x90_1'
    | 'free_this_week_728x90_2'
    | 'become_a_creator_728x90_1'
    | 'genre_page_728x90_1'
    | 'category_page_728x90_1'
    | 'series_page_728x90_1'
    | 'flip_page_728x90_1'
    | 'flip_inside_ad'
    | 'spin_page_728x90_1';
}

export function Ads(props: AdsProps) {
  const sections: Record<AdsProps['section'], string> = {
    home_728x90_1: '9804967437',
    home_728x90_2: '1442712941',
    free_this_week_728x90_1: '9327327422',
    free_this_week_728x90_2: '5388082419',
    become_a_creator_728x90_1: '8149777438',
    genre_page_728x90_1: '5533005349',
    category_page_728x90_1: '2318866252',
    series_page_728x90_1: '5393404542',
    flip_page_728x90_1: '2767241203',
    flip_inside_ad: '1015387201',
    spin_page_728x90_1: '1454159538',
  };

  // onMount(() => {
  //   (window as any).adsbygoogle = (window as any).adsbygoogle || [];
  //   (window as any).adsbygoogle.push({});
  // });

  createEffect(() => {
    // Load AdSense script
    const script = document.createElement('script');
    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8827626467590248';
    script.async = true;
    script.crossOrigin = 'anonymous';
    try {
      const el = document.getElementById(props.section);
      el && el.appendChild(script);
    } catch (err) {
      console.error(err);
    }

    // Initialize ads
    script.onload = () => {
      (window as any).adsbygoogle = (window as any).adsbygoogle || [];
      (window as any).adsbygoogle.push({});
    };
  });

  return (
    <>
      <div
        id={props.section}
        class={cn('mx-auto text-center my-4', props.class)}
      >
        <ins
          class={'bg-white max-w-[780px] h-[90px] w-full adsbygoogle'}
          // style='display:block'
          style="display:inline-block;width:728px;height:90px"
          data-ad-client="ca-pub-8827626467590248"
          data-ad-slot={sections[props.section] ?? ''}
          // data-ad-format="auto"
          // data-full-width-responsive="true"
        ></ins>
      </div>
      {/* <!-- GOOGLE ADS: START --> */}
      {/* <!-- https://www.google.com/adsense/new/u/3/pub-8827626467590248/myads/units/display/9804967437/generate-ad-code --> */}
      {/* <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8827626467590248"
          crossorigin="anonymous"></script> */}
      {/* <!-- GOOGLE ADS: END --> */}
    </>
  );
}
